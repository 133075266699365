import CustomHead from '../components/CustomHead';
import Error404Main from '../components/Error404Main';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Navigation from '../components/Navigation/Navigation';
import { getOrFetchFooterLinks } from '../utils/footerLinks';
import { getOrFetchNavigationLinks } from '../utils/navigationLinks';

export default function Page404({ navigation, footerLinks }) {
  return (
    <>
      <CustomHead title="Page not found">
        <meta name="description" content="Page not found" />
      </CustomHead>
      <Header navigation={navigation} />

      <Navigation navigation={navigation} />

      <Error404Main />

      <Footer footerLinks={footerLinks} />
    </>
  );
}

export async function getStaticProps() {
  const [{ data: navigation }, footerLinks] = await Promise.all([getOrFetchNavigationLinks(), getOrFetchFooterLinks()]);

  return {
    props: { navigation, footerLinks },
  };
}
