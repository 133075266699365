import Link from 'next/link';
import { RiFileWarningLine } from 'react-icons/ri';

import styles from './ErrorPageMain.module.scss';

export default function Error404Main() {
  return (
    <section className={styles.container}>
      <div className={styles.imageContainer}>
        <RiFileWarningLine className={styles.img} />
      </div>
      <div className={styles.errorText}>
        <p>Λυπούμαστε, η σελίδα δεν βρέθηκε.</p>
      </div>
      <Link href="/">
        <a className={styles.link}>Επιστροφή στην αρχική</a>
      </Link>
    </section>
  );
}
